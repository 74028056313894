"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.receiveInteractiveQuote = exports.fetchQuotes = void 0;

var types = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../thunk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fetchQuotes = function (listingIds) {
  var live = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return (0, _thunk.thunkCreator)({
    types: [types.FTC_QUOTES_RQ, types.FTC_QUOTES_OK, types.FTC_QUOTES_FAI],
    promise: live ? _apiSdk.v2API.Quotes.getLiveQuotes(listingIds) : _apiSdk.v2API.Quotes.getDelayedQuotes(listingIds)
  });
};

exports.fetchQuotes = fetchQuotes;

var receiveInteractiveQuote = update => {
  return {
    type: types.FTC_QUOTES_OK,
    result: [update]
  };
};

exports.receiveInteractiveQuote = receiveInteractiveQuote;