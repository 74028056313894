"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.togglePost = togglePost;
exports.likeInfo = likeInfo;
exports.addLikesInfo = addLikesInfo;

var types = _interopRequireWildcard(require("../actionTypes.js"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function togglePost(postId, toggleType) {
  return {
    type: types.TOGGLE_POST,
    postId: postId,
    toggleType: toggleType
  };
}

function likeInfo(postId, likeInfo) {
  return {
    type: types.POST_INFO,
    postId: postId,
    likeInfo: likeInfo
  };
}

function addLikesInfo(likesInfo) {
  return {
    type: types.ADD_POSTS_INFO,
    likesInfo: likesInfo
  };
}