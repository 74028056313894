"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAvatarURI = setAvatarURI;
exports.setUserName = setUserName;
exports.addData = addData;
exports.saveChanges = exports.fetchUserData = exports.fetchUserByLogin = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../thunk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fetchUserByLogin = login => {
  return (0, _thunk.thunkCreator)({
    login,
    types: [T.FTC_USERS_BY_LOGIN_RQ, T.FTC_USERS_BY_LOGIN_OK, T.FTC_USERS_BY_LOGIN_FAI],
    promise: _apiSdk.legacyAPI.Users.getUserIdFromUserLogin(login)
  });
};

exports.fetchUserByLogin = fetchUserByLogin;

var fetchUserData = function (userId) {
  var counters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return (0, _thunk.thunkCreator)({
    userId,
    types: [T.FTC_USERS_DATA_RQ, T.FTC_USERS_DATA_OK, T.FTC_USERS_DATA_FAI],
    promise: Promise.all([_apiSdk.legacyAPI.Users.getBPData(userId, [1, 6, 4, 7, 51, 96]), counters ? _apiSdk.legacyAPI.Users.getFollowerCount(userId) : Promise.resolve(0), counters ? _apiSdk.legacyAPI.Users.getPostCount(userId) : Promise.resolve(0), _apiSdk.legacyAPI.Users.getUserLogin(userId), _apiSdk.legacyAPI.Users.getProfilePicture(userId)])
  });
};

exports.fetchUserData = fetchUserData;

function setAvatarURI(userId, uri) {
  return {
    type: T.USERS.SET.ONE.AVATAR,
    userId,
    uri: uri
  };
}

function setUserName(userId, name) {
  return {
    type: T.USERS.SET.ONE.NAME,
    userId,
    name
  };
}

function addData(userId, data) {
  return {
    type: T.USERS.SET.ONE.DATA,
    userId,
    data
  };
}

var saveChanges = data => (dispatch, getState) => {
  var mappings = {
    "1": "firstName",
    "6": "lastName",
    "2": "mobile",
    "8": "landline",
    "15": "city",
    "4": "companyName"
  };
  return _apiSdk.legacyAPI.Users.setBPData(data).then(() => {
    data = Object.keys(mappings).reduce((accumulator, currentValue) => {
      if (data[currentValue]) {
        accumulator[mappings[currentValue]] = data[currentValue];
      }

      return accumulator;
    }, {});

    if (data[1] && data[6]) {
      data.name = "".concat(data[1], " ").concat(data[6]);
    }

    dispatch(addData(getState().userData.id, data));
  });
};

exports.saveChanges = saveChanges;