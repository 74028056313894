"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchByCollectionId = fetchByCollectionId;

var T = _interopRequireWildcard(require("../../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../../thunk");

var PricesActions = _interopRequireWildcard(require("../../prices/actions"));

var ListingsActions = _interopRequireWildcard(require("../../listings/actions"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function fetchByCollectionId(collectionId) {
  return function (dispatch) {
    dispatch((0, _thunk.thunkCreator)({
      types: [T.MARKETS.FTC.LNGS_BY.COLLECTION_ID.RQ, T.MARKETS.FTC.LNGS_BY.COLLECTION_ID.OK, T.MARKETS.FTC.LNGS_BY.COLLECTION_ID.FAI],
      promise: _apiSdk.v2API.MarketMovers.getByCollectionId(collectionId).then(data => {
        if (data) {
          var collection = data[collectionId];

          if (collection && collection.rankings) {
            var joined = collection.rankings.top.concat(collection.rankings.bottom);
            dispatch(PricesActions.fetchByIds(joined));
            dispatch(ListingsActions.fetchByIds(joined, false, true));
          }
        }

        return data;
      })
    }));
  };
}