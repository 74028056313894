"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setListings = setListings;
exports.fetchWinners = fetchWinners;
exports.fetchLosers = fetchLosers;
exports.setSelectedIndex = setSelectedIndex;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _thunk = require("../thunk");

var _apiSdk = require("@voxmarkets/api-sdk");

var MarketMoversActions = _interopRequireWildcard(require("./movers/actions"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function setListings(listingIds, index, kind) {
  return {
    type: T.SET_MARKET_LNGS,
    listingIds: listingIds,
    index: index,
    kind: kind
  };
}

function fetchWinners(index, limit) {
  return (0, _thunk.thunkCreator)({
    types: [T.MARKETS.FTC.WINNERS.RQ, T.MARKETS.FTC.WINNERS.OK, T.MARKETS.FTC.WINNERS.FAI],
    promise: _apiSdk.v2API.Markets.getWinners(index, limit)
  });
}

function fetchLosers(index, limit) {
  return (0, _thunk.thunkCreator)({
    types: [T.MARKETS.FTC.LOSERS.RQ, T.MARKETS.FTC.LOSERS.OK, T.MARKETS.FTC.LOSERS.FAI],
    promise: _apiSdk.v2API.Markets.getLosers(index, limit)
  });
}

function setSelectedIndex() {
  var indice = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function (dispatch, getState) {
    if (!getState().marketMovers.byCollectionId[indice.collectionId]) {
      dispatch(MarketMoversActions.fetchByCollectionId(indice.collectionId));
    }

    dispatch({
      type: T.MARKETS.SET.SELECTED.INDEX,
      indice
    });
  };
}