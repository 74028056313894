"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.insertForListings = insertForListings;
exports.fetchAll = exports.fetchListingMarketIndices = void 0;

var T = _interopRequireWildcard(require("../../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../../thunk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fetchListingMarketIndices = listingId => (0, _thunk.thunkCreator)({
  types: [T.FTC_LNG_MARKET_INDICES_RQ, T.FTC_LNG_MARKET_INDICES_OK, T.FTC_LNG_MARKET_INDICES_FAI],
  promise: _apiSdk.v2API.MarketIndices.getListingMarketIndices(listingId),
  listingId: listingId
});

exports.fetchListingMarketIndices = fetchListingMarketIndices;

var fetchAll = () => (0, _thunk.thunkCreator)({
  types: [T.MARKETS.FTC.INDICES.ALL.RQ, T.MARKETS.FTC.INDICES.ALL.OK, T.MARKETS.FTC.INDICES.ALL.FAI],
  promise: _apiSdk.v2API.MarketIndices.getAll()
});

exports.fetchAll = fetchAll;

function insertForListings(byListing) {
  return {
    type: T.MARKETS.SET.INDICES.FOR.LNGS,
    result: byListing
  };
}