"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = quotesReducer;

var _mapValues2 = _interopRequireDefault(require("lodash/mapValues"));

var _reduce2 = _interopRequireDefault(require("lodash/reduce"));

var types = _interopRequireWildcard(require("../actionTypes.js"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {};
var tradingPhases = ["CLOS", "CLOG", "CPX", "PosC", "EoPC", "SUSP"]; // transforms the quote data from the API into data we can use

var transformQuote = quote => {
  var quoteTx = (0, _mapValues2.default)(quote, o => {
    if (o && o.hasOwnProperty("$numberDecimal")) {
      return Number(o["$numberDecimal"]);
    }

    return o;
  });
  var displayPrice = getDisplayPrice(quoteTx);
  return _objectSpread(_objectSpread({}, quoteTx), {}, {
    displayPrice: displayPrice,
    updateTime: quoteTx.timeOfLastUpdateGMT,
    decimalPlaces: getDecimalPlaces(displayPrice)
  });
};
/**
 * Returns the number of decimal places
 * @param {Number} displayPrice
 */


var getDecimalPlaces = displayPrice => {
  if (displayPrice < 0.1) {
    return 4;
  } else if (displayPrice < 10) {
    return 3;
  } else {
    return 2;
  }
};
/**
 * Pulls out a correct value to display as price from snapshot.
 * Otherwise it will use "close_price" field within closed period, or headline_price
 * @param {Object} quote - price snapshot
 * Live pricing snapshot should set this flag to false
 * @return {number}
 */


var getDisplayPrice = quote => {
  if (tradingPhases.includes(quote.tradingPhase)) {
    return quote.close;
  } else if (quote.headline || quote.headline === 0) {
    return quote.headline;
  } else {
    return quote.mid;
  }
};

function quotesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case types.FTC_QUOTES_OK:
      if (action.result !== undefined && action.result !== null && action.result.length > 0) {
        // unpack the quotes we have received and arrange them into a dictionary
        var updatedQuotes = (0, _reduce2.default)(action.result, (acc, quote) => {
          acc[quote.listingId] = transformQuote(quote);
          return acc;
        }, {}); // combine the new quotes, overwriting previous ones to create the new state

        return _objectSpread(_objectSpread({}, state), updatedQuotes);
      } else {
        return _objectSpread({}, state);
      }

    default:
      return state;
  }
}