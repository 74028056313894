"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = pricesReducer;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _Utils = _interopRequireDefault(require("../Utils"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var initialState = {};

function pricesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case T.UPSERT_PRICE:
      return _Utils.default.upsertOne(state, action.listingId, action.snapshot);

    case T.COMBI_LP_BATCH_UPDATE:
      return _Utils.default.upsertChanged(state, action.snapshots);

    case T.COMBI_WL_ADD_LNG_UPSERT_DATA:
    case T.COMBI_SET_WL_CPNY_LNGS:
    case T.UPSERT_PRICES:
      return _Utils.default.upsertMany(state, action.prices);

    case T.PRICES.FTC.MANY.RQ:
      return _Utils.default.setAsPending(state, action.listingIds);

    case T.PRICES.FTC.MANY.OK:
      return _Utils.default.upsertMany(state, action.result);

    case T.CLEAR:
      return {};

    default:
      return state;
  }
}