"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setFilters = exports.fetchMeta = exports.fetchContentByStoryIds = void 0;

var _thunk = require("../thunk");

var _apiSdk = require("@voxmarkets/api-sdk");

var T = _interopRequireWildcard(require("../actionTypes.js"));

var newsMetadataActions = _interopRequireWildcard(require("../newsMetadata/actions"));

var _reducer = require("./reducer");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fetchContentByStoryIds = storyIds => (0, _thunk.thunkCreator)({
  types: [T.RNS.FTC.CONTENT_BY.STORY_IDS.RQ, T.RNS.FTC.CONTENT_BY.STORY_IDS.OK, T.RNS.FTC.CONTENT_BY.STORY_IDS.FAI],
  promise: _apiSdk.v2API.RNS.getContentByStoryIds(storyIds)
});
/**
 * @param {Number} direction - 1 for newer than X timestamp, -1 for older than X timestamp
 */


exports.fetchContentByStoryIds = fetchContentByStoryIds;

var fetchMeta = direction => (dispatch, getState) => {
  var {
    timeBoundsPerFilter,
    typeFilterId,
    indexFilterId,
    filtersWithNoMoreData
  } = getState().rns;
  var filtersKey = "".concat(typeFilterId, "#").concat(indexFilterId);
  var bounds = timeBoundsPerFilter[filtersKey];
  direction = direction == undefined ? bounds ? 1 : -1 : direction;

  if (direction == -1 && filtersWithNoMoreData.includes(filtersKey)) {} else {
    var marketIndexId = _reducer.indexOptions[indexFilterId].key;
    var timestamp = bounds ? direction == 1 ? bounds.max + 1 : bounds.min - 1 : Math.floor(Date.now() / 1000);

    if (marketIndexId == "watched") {
      dispatch(newsMetadataActions.fetchForWatchedCompanies(timestamp, direction));
    } else if (!marketIndexId || marketIndexId == "all") {
      dispatch(newsMetadataActions.getMeta(timestamp, [_reducer.typeOptions[typeFilterId].key], direction));
    } else {
      dispatch(newsMetadataActions.fetchForMarket(marketIndexId, timestamp, direction));
    }
  }
};

exports.fetchMeta = fetchMeta;

var setFilters = (typeFilterId, indexFilterId) => dispatch => {
  typeFilterId = Math.max(0, Math.min(_reducer.typeOptions.length - 1, Number(typeFilterId) || 0));
  indexFilterId = Math.max(0, Math.min(_reducer.indexOptions.length - 1, Number(indexFilterId) || 0));
  if (indexFilterId != 0) typeFilterId = 0;
  dispatch({
    type: T.RNS.SET_FILTERS,
    typeFilterId,
    indexFilterId
  });
  dispatch(fetchMeta());
};

exports.setFilters = setFilters;