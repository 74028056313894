"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTrades = addTrades;
exports.addTrade = addTrade;
exports.livePriceBatchUpdate = livePriceBatchUpdate;
exports.fetchForListing = exports.fetchListingTrades = void 0;

var types = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../thunk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fetchListingTrades = function (listingId) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
  var timestamp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new Date().getTime() / 1000;
  var limit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 100;
  return (0, _thunk.thunkCreator)({
    listingId: listingId,
    types: [types.FTC_TRADES_RQ, types.FTC_TRADES_OK, types.FTC_TRADES_FAI],
    promise: _apiSdk.v2API.Trades.getListingTrades(listingId, direction, timestamp, limit)
  });
};

exports.fetchListingTrades = fetchListingTrades;

function addTrades(listingId, trades) {
  return {
    type: types.ADD_TRADES,
    listingId: listingId,
    trades: trades
  };
}

function addTrade(listingId, trade) {
  return {
    type: types.ADD_TRADE,
    listingId: listingId,
    trade: trade
  };
}

function livePriceBatchUpdate(snapshots, trades) {
  return {
    type: types.COMBI_LP_BATCH_UPDATE,
    snapshots: snapshots,
    trades: trades
  };
}

var fetchForListing = (listingId, beforeTimestamp) => (0, _thunk.thunkCreator)({
  listingId,
  types: [types.TRADES.FTC.RQ, types.TRADES.FTC.OK, types.TRADES.FTC.FAI],
  promise: _apiSdk.v2API.Trades.getForListing([listingId], beforeTimestamp)
});

exports.fetchForListing = fetchForListing;