"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.upsertOne = upsertOne;
exports.upsertMany = upsertMany;
exports.getByIds = exports.getByShortTicker = exports.getById = exports.getList = exports.fetchByShortTicker = exports.fetchCompanyListings = exports.fetchByIds = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../thunk");

var IssuesActions = _interopRequireWildcard(require("../issues/actions"));

var MarketsIndicesActions = _interopRequireWildcard(require("../markets/indices/actions"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var unprefix = listing => _objectSpread({
  tickerShort: listing.listingShortTicker,
  ticker: listing.listingTicker
}, listing);
/**
 * Upserts single listing object into store
 * @param {string} listingId
 * @param {Object} listing
 * @return
 */


function upsertOne(listingId, listing) {
  return {
    type: T.UPSERT_LNG,
    listing: listing,
    listingId: listingId
  };
}
/**
 * Upserts dictionary of listing into store
 * @param {string} listingId
 * @param {Object} listings - dictionary of objects
 * @return
 */


function upsertMany(listings) {
  return {
    type: T.UPSERT_LNGS,
    listings: listings
  };
}

var fetchByIds = function (listingIds) {
  var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var includeIssues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var includeMarketIndices = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return (dispatch, getState) => {
    if (!force) {
      var currentListings = getState().listings;
      listingIds = listingIds.filter(listingId => !(currentListings[listingId] && currentListings[listingId].pending));
    }

    var include = [];

    if (includeIssues) {
      include.push("issue");
    }

    if (includeMarketIndices) {
      include.push("marketIndices");
    }

    return dispatch((0, _thunk.thunkCreator)({
      listingIds,
      types: [T.LNGS.FTC.MANY.RQ, T.LNGS.FTC.MANY.OK, T.LNGS.FTC.MANY.FAI],
      promise: _apiSdk.v2API.Listings.getByIds(listingIds, include).then(data => {
        var {
          includes
        } = data;

        if (data.includes) {
          if (includes.issues) {
            dispatch(IssuesActions.upsertMany(includes.issues));
            delete includes.issues;
          }

          if (includes.marketIndices) {
            dispatch(MarketsIndicesActions.insertForListings(includes.marketIndices));
            delete includes.marketIndices;
          }
        }

        return data;
      })
    }));
  };
};

exports.fetchByIds = fetchByIds;

var fetchCompanyListings = function (companyId) {
  var includeListings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return (0, _thunk.thunkCreator)({
    companyId,
    types: [T.LNGS.FTC.MANY.RQ, T.LNGS.FTC.MANY.OK, T.LNGS.FTC.MANY.FAI],
    promise: _apiSdk.v2API.Issues.getByCompanyId(companyId, includeListings).then(data => {
      var listings = data.reduce((indexed, issue) => {
        issue.listings.forEach(listing => {
          indexed[listing.id] = Object.assign(unprefix(listing), {
            issueId: issue.id
          });
        });
        return indexed;
      }, {});
      return listings;
    })
  });
}; // deprecated, use getByShortTicker


exports.fetchCompanyListings = fetchCompanyListings;

var fetchByShortTicker = shortTicker => (0, _thunk.thunkCreatorWithoutConcurrency)({
  shortTicker,
  types: [T.LNGS_FTC_ONE_RQ, T.LNGS_FTC_ONE_OK, T.LNGS_FTC_ONE_FAI],
  promise: () => _apiSdk.v2API.Listings.getByShortTicker(shortTicker)
}); //----------------------------------
// Returns DB Type Object nad promises
//------------------------------


exports.fetchByShortTicker = fetchByShortTicker;

var getList = filter => (0, _thunk.thunkCreatorWithoutConcurrency)({
  filter,
  types: [T.LNGS_GET_LIST_RQ, T.LNGS_GET_LIST_OK, T.LNGS_GET_LIST_FAI],
  promise: () => _apiSdk.v2API.Listings.getList(filter)
});

exports.getList = getList;

var getById = id => {
  return getList({
    where: {
      id
    }
  });
};

exports.getById = getById;

var getByShortTicker = shortTicker => {
  return getList({
    where: {
      and: [{
        listingShortTicker: {
          like: shortTicker
        }
      }, {
        listingEndDate: null
      }]
    }
  });
};

exports.getByShortTicker = getByShortTicker;

var getByIds = listingIds => {
  return getList({
    where: {
      id: {
        inq: listingIds
      }
    }
  });
};

exports.getByIds = getByIds;