"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setReactions = setReactions;
exports.fetchMyReaction = exports.fetchMineForItem = exports.fetchForItems = exports.fetchForItem = exports.postReaction = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _thunk = require("../thunk");

var _apiSdk = require("@voxmarkets/api-sdk");

var _batchHelper = _interopRequireDefault(require("../batchHelper"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/**
 * @param {Object} reactions - object containing reactions for one or more stories
 */
function setReactions(reactions) {
  return {
    type: T.REACTIONS.SET,
    reactions
  };
}

var postReaction = (targetId, reaction) => (0, _thunk.thunkCreator)({
  id: targetId,
  types: [T.REACTIONS.POST.RQ, T.REACTIONS.POST.OK, T.REACTIONS.POST.FAI],
  promise: _apiSdk.v2API.Reactions.postReaction(targetId, reaction)
});

exports.postReaction = postReaction;
var fetchForItemBatched = (0, _batchHelper.default)(_apiSdk.v2API.Reactions.getForItemsByArray, (resp, ids) => {
  resp && resp.reactionSummaries.forEach(r => {
    ids[r.target].resolve(r);
  });
});

var fetchForItem = targetId => (0, _thunk.thunkCreator)({
  id: targetId,
  types: [T.REACTIONS.GET.RQ, T.REACTIONS.GET.OK, T.REACTIONS.GET.FAI],
  promise: fetchForItemBatched(targetId)
});

exports.fetchForItem = fetchForItem;

var fetchForItems = targets => dispatch => _apiSdk.v2API.Reactions.getForItemsByArray(targets).then(result => dispatch({
  type: T.REACTIONS_GET_MANY_OK,
  result
}));

exports.fetchForItems = fetchForItems;

var fetchMineForItem = targetId => (0, _thunk.thunkCreator)({
  id: targetId,
  types: [T.REACTIONS.GET.MINE.RQ, T.REACTIONS.GET.MINE.OK, T.REACTIONS.GET.MINE.FAI],
  promise: _apiSdk.v2API.Reactions.getMyReactionToItem(targetId)
});

exports.fetchMineForItem = fetchMineForItem;

var fetchMyReaction = reactionId => (0, _thunk.thunkCreator)({
  id: reactionId,
  types: [T.REACTIONS.GET.MINE.RQ, T.REACTIONS.GET.MINE.OK, T.REACTIONS.GET.MINE.FAI],
  promise: _apiSdk.v2API.Reactions.getMyReaction(reactionId)
});

exports.fetchMyReaction = fetchMyReaction;