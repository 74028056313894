"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.remove = exports.create = exports.fetch = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//get
var fetch = function () {
  var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return dispatch => _apiSdk.v2API.Reactions.getMyList(filter).then(result => {
    return dispatch({
      type: T.USER_REACTIONS_FTC_OK,
      result
    });
  }).catch(e => {
    console.log(e);
  });
}; //add


exports.fetch = fetch;

var create = (targetId, reaction) => dispatch => _apiSdk.v2API.Reactions.postReaction(targetId, reaction).then(result => {
  return dispatch({
    type: T.USER_REACTIONS_CREATE_OK,
    result
  });
}).catch(e => {
  console.log(e);
}); // remove


exports.create = create;

var remove = reactionId => dispatch => _apiSdk.v2API.Reactions.deleteReaction(reactionId).then(() => {
  return dispatch({
    type: T.USER_REACTIONS_DELETE_OK,
    reactionId
  });
}).catch(e => {
  console.log(e);
});

exports.remove = remove;