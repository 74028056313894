"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reactionsReducer;

var T = _interopRequireWildcard(require("../actionTypes.js"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  byTargetId: {},
  mine: {}
};

function reactionsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case T.REACTIONS.SET:
      return _objectSpread(_objectSpread({}, state), action.reactions);

    case T.REACTIONS.LIKE.RQ:
      return toggleLike(state, action.id);

    case T.REACTIONS.DISLIKE.RQ:
      return toggleDislike(state, action.id);

    case T.COMMENTS.POST.OK:
      return increaseComments(state, action.id);

    case T.COMMENTS.FTC.OK:
      return setCommentCount(state, action);

    case T.TLN.FTC.OK.PERSONAL:
    case T.TLN.FTC.OK.CPNY:
    case T.TLN.FTC.OK.USER:
      return updateReactions(state, action);

    case T.TLN.POST.OK.PERSONAL:
      return setDefault(state, action);

    case T.REACTIONS.POST.OK:
      return reacted(state, action);

    case T.REACTIONS.GET.OK:
      return addReaction(state, action);

    case T.REACTIONS.GET.MINE.OK:
      return updateMyReactions(state, action);

    case T.REACTIONS_GET_MANY_OK:
      var byTargetId = action.result.reactionSummaries.reduce((acc, item) => {
        acc[item.target] = item.reactions;
        return acc;
      }, _objectSpread({}, state.byTargetId));
      return _objectSpread(_objectSpread({}, state), {}, {
        byTargetId
      });

    default:
      return state;
  }
}

function reacted(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    byTargetId: Object.assign({}, state.byTargetId, {
      [action.result.target]: action.result.summary.reactions
    }),
    mine: Object.assign({}, state.mine, {
      [action.result.target]: {
        reaction: action.result.reaction,
        id: action.result.id
      }
    })
  });
}

function addReaction(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    byTargetId: Object.assign({}, state.byTargetId, {
      [action.result.target]: action.result.reactions
    })
  });
}

function updateMyReactions(state, action) {
  var result = action.result.reduce((reactions, item) => {
    if (item.hasOwnProperty("target")) {
      return _objectSpread(_objectSpread({}, reactions), {}, {
        [item.target]: {
          reaction: item.reaction,
          id: item.id
        }
      });
    }

    return reactions;
  }, {
    [action.id]: false
  });
  return _objectSpread(_objectSpread({}, state), {}, {
    mine: Object.assign({}, state.mine, result)
  });
}
/*** LEGACY ***/


function setDefault(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    [action.result.id]: getCurrent(state, action.result.id)
  });
}

function updateReactions(state, action) {
  return _objectSpread(_objectSpread({}, state), action.result.reactions);
}

function toggleLike(state, id) {
  var current = getCurrent(state, id);
  return updateState(state, id, current, like(current));
}

function toggleDislike(state, id) {
  var current = getCurrent(state, id);
  return updateState(state, id, current, dislike(current));
}

function increaseComments(state, id) {
  var current = getCurrent(state, id);
  return updateState(state, id, current, {
    commentCount: current.commentCount + 1
  });
}

function setCommentCount(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    [action.id]: _objectSpread(_objectSpread({}, getCurrent(state, action.id)), {}, {
      commentCount: action.result.length
    })
  });
}

function getCurrent(state, id) {
  return state[id] || {
    likes: 0,
    dislikes: 0,
    liked: false,
    disliked: false,
    commentCount: 0
  };
}

function like(current) {
  return {
    likes: current.liked ? current.likes - 1 : current.likes + 1,
    dislikes: current.disliked ? current.dislikes - 1 : current.dislikes,
    liked: !current.liked,
    disliked: false
  };
}

function dislike(current) {
  return {
    likes: current.liked ? current.likes - 1 : current.likes,
    dislikes: current.disliked ? current.dislikes - 1 : current.dislikes + 1,
    liked: false,
    disliked: !current.disliked
  };
}

function updateState(state, id, current, updated) {
  return _objectSpread(_objectSpread({}, state), {}, {
    [id]: _objectSpread(_objectSpread({}, current), updated)
  });
}