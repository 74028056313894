"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = tradesReducer;

var _map2 = _interopRequireDefault(require("lodash/map"));

var _omit2 = _interopRequireDefault(require("lodash/omit"));

var types = _interopRequireWildcard(require("../actionTypes.js"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// maximum number of trades per key
var limit = 999;
var initialState = {
  pending: {}
};

function tradesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case types.TRADES.FTC.RQ:
      return setPending(state, action);

    case types.TRADES.FTC.OK:
      return _objectSpread(_objectSpread({}, addTrades(state, {
        listingId: action.listingId,
        trades: action.result
      })), {}, {
        pending: (0, _omit2.default)(state.pending, [action.listingId])
      });

    case types.COMBI_LP_BATCH_UPDATE:
      return addTradesDict(state, action);

    case types.ADD_TRADES:
      return addTrades(state, _objectSpread(_objectSpread({}, action), {}, {
        trades: transformGraphQLTrades(action.trades)
      }));

    case types.ADD_TRADE:
      return addTrade(state, action);

    case types.FTC_TRADES_OK:
      return addTrades(state, _objectSpread(_objectSpread({}, action), {}, {
        trades: transformTrades(action.result)
      }));

    case types.CLEAR:
      return initialState;

    default:
      return state;
  }
}

function setPending(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    pending: _objectSpread(_objectSpread({}, state.pending), {}, {
      [action.listingId]: true
    })
  });
}

var transformTrades = rawTrades => {
  return (0, _map2.default)(rawTrades, rawTrade => {
    return {
      id: rawTrade.id,
      tradeDirection: parseInt(rawTrade.tradeDirection),
      price: Number(rawTrade.price["$numberDecimal"]),
      time: rawTrade.time,
      volume: rawTrade.volume
    };
  });
};

var transformGraphQLTrades = rawTrades => {
  return (0, _map2.default)(rawTrades, rawTrade => {
    var {
      tradeId
    } = rawTrade,
        trade = _objectWithoutProperties(rawTrade, ["tradeId"]);

    return _objectSpread(_objectSpread({}, trade), {}, {
      id: tradeId
    });
  });
};
/**
 * Compares time in trades
 */


var timeComparator = (t1, t2) => t2.time - t1.time;
/**
 * Merges two arrays, without duplicates
 * @param {Object[]} left - existing trades array
 * @param {Object[]} right - trades array to be added to the existing ones
 * @return {Object[]}
 *
 * Benchmarked 4x@3.7GHz 16GB 2133 RAM:
 * 2 array, 10,000 items each.
 * Completion time: 67ms
 */


function mergeLeft() {
  var left = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var right = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  //sort both arrays for quicker concatination
  //Left is is probably already sorted, so it takes no time.
  left = left.sort((t1, t2) => {
    return t2.time - t1.time;
  });
  right = right.sort((t1, t2) => {
    return t2.time - t1.time;
  });
  var merged = []; //left array index

  var leftPos = 0;
  var leftLength = left.length;
  var addedTradeIds = {};

  for (var rightTrade of right) {
    //Only check if trade to be added does not exist in the trades
    while (leftPos < leftLength && rightTrade.time < left[leftPos].time) {
      var leftTrade = left[leftPos]; //only add to merged if not already added

      if (!addedTradeIds[leftTrade.id]) {
        addedTradeIds[leftTrade.id] = 1;
        merged.push(leftTrade);
      }

      leftPos++;
    }

    if (!addedTradeIds[rightTrade.id]) {
      addedTradeIds[rightTrade.id] = 1;
      merged.push(rightTrade);
    }
  } //add all other trades which are older than oldest right


  while (leftPos < leftLength) {
    var _leftTrade = left[leftPos];

    if (!addedTradeIds[_leftTrade.id]) {
      merged.push(_leftTrade);
    }

    leftPos++;
  }

  return merged.sort(timeComparator);
}
/**
 * Checks if an array contains object with with tradeId field given in argument.
 * Starts at a starting point, or beginning by default
 * @param {Object[]} trades - array of trades
 * @param {string} tradeId - identifier given to this trade by the relevant exchange
 * @param {number} start - starting position
 * @return {boolean}
 */


function contains() {
  var trades = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var tradeId = arguments.length > 1 ? arguments[1] : undefined;
  var start = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  for (var i = start; i < trades.length; i++) {
    if (trades[i].id === tradeId) return true;
  }

  return false;
}
/**
 * Adds a single trade to the state under listingId.
 * @param {Object} currentState
 * @param {Object} action - {listingId: string, trade: Object}
 * @return new state
 */


function addTrade(currentState, action) {
  var currTrades = currentState[action.listingId];

  var _action$trade = action.trade,
      {
    tradeId
  } = _action$trade,
      rawTrade = _objectWithoutProperties(_action$trade, ["tradeId"]);

  var trade = _objectSpread(_objectSpread({}, rawTrade), {}, {
    id: tradeId
  });

  return Object.assign({}, currentState, {
    [action.listingId]: currTrades ? contains(currTrades, trade.id) ? currTrades : [trade, ...currTrades.slice(0, limit)].sort(timeComparator) : [trade]
  });
}
/**
 * Insert mutliple trades
 * @param {Object} currentState
 * @param {Object} action - {listingId: string, trade: Object}
 * @return {Object} new state
 */


function addTrades(currentState, action) {
  if (!action.trades) return currentState;
  return Object.assign({}, currentState, {
    [action.listingId]: currentState[action.listingId] ? mergeLeft(currentState[action.listingId], action.trades) : mergeLeft([], action.trades)
  });
}

function addTradesDict(state, action) {
  var newTrades = {};

  for (var listingId in action.trades) {
    newTrades[listingId] = state[listingId] ? mergeLeft(state[listingId], transformGraphQLTrades(action.trades[listingId])) : mergeLeft([], transformGraphQLTrades(action.trades[listingId]));
  }

  return Object.assign({}, state, newTrades);
}