"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deletePriceAlert = exports.updatePriceAlert = exports.createPriceAlert = exports.fetchPriceAlerts = void 0;

var types = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../thunk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fetchPriceAlerts = () => (0, _thunk.thunkCreatorWithoutConcurrency)({
  types: [types.FTC_PRICE_ALERTS_RQ, types.FTC_PRICE_ALERTS_OK, types.FTC_PRICE_ALERTS_FAI],
  promise: () => _apiSdk.v2API.PriceAlerts.getPriceAlerts()
});

exports.fetchPriceAlerts = fetchPriceAlerts;

var createPriceAlert = alert => (0, _thunk.thunkCreatorWithoutConcurrency)({
  alert,
  types: [types.CREATE_PRICE_ALERT_RQ, types.CREATE_PRICE_ALERT_OK, types.CREATE_PRICE_ALERT_FAI],
  promise: () => _apiSdk.v2API.PriceAlerts.createPriceAlert(alert)
});

exports.createPriceAlert = createPriceAlert;

var updatePriceAlert = (alertId, alert) => (0, _thunk.thunkCreatorWithoutConcurrency)({
  alertId,
  alert,
  types: [types.UPDATE_PRICE_ALERT_RQ, types.UPDATE_PRICE_ALERT_OK, types.UPDATE_PRICE_ALERT_FAI],
  promise: () => _apiSdk.v2API.PriceAlerts.updatePriceAlert(alertId, alert)
});

exports.updatePriceAlert = updatePriceAlert;

var deletePriceAlert = alertId => (0, _thunk.thunkCreatorWithoutConcurrency)({
  alertId: alertId,
  types: [types.DELETE_PRICE_ALERT_RQ, types.DELETE_PRICE_ALERT_OK, types.DELETE_PRICE_ALERT_FAI],
  promise: () => _apiSdk.v2API.PriceAlerts.deletePriceAlert(alertId)
});

exports.deletePriceAlert = deletePriceAlert;