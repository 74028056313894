"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var types = _interopRequireWildcard(require("../actionTypes.js"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//---------------------------
function list() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FOLLOWS_FTC_OK:
      return action.result.reduce((state, follow) => {
        state[follow.id] = follow;
        return state;
      }, _objectSpread({}, state));

    case types.FOLLOWS_CREATE_OK:
    case types.FOLLOWS_UPDATE_OK:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.result.id]: action.result
      });

    case types.FOLLOWS_DELETE_OK:
      delete state[action.followId];
      return _objectSpread({}, state);

    default:
      return state;
  }
} //---------------------------


function counterByFollowedId() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FOLLOWS_COUNT_FOLLOWED_OK:
      return _objectSpread({
        [action.followedId]: _objectSpread(_objectSpread({}, state[action.followedId]), {}, {
          [action.followedType]: action.result.count
        })
      }, state);

    default:
      return state;
  }
} //---------------------------


function counterByOwner() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FOLLOWS_COUNT_OWNER_OK:
      return _objectSpread({
        [action.owner]: _objectSpread(_objectSpread({}, state[action.owner]), {}, {
          [action.followedType]: action.result.count
        })
      }, state);

    default:
      return state;
  }
}

var _default = (0, _redux.combineReducers)({
  list,
  counterByFollowedId,
  counterByOwner
});

exports.default = _default;