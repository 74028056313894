"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFollowing = isFollowing;
exports.follow = follow;
exports.unfollow = unfollow;
exports.getFollowers = getFollowers;

var types = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function isFollowing(userID, status) {
  if (typeof status !== "undefined") {
    return {
      type: types.USER_IS_FOLLOWING,
      userID,
      following: status
    };
  }

  return dispatch => {
    dispatch({
      type: types.USER_FOLLOWING_CHECK,
      userID,
      progress: true
    });

    _apiSdk.legacyAPI.Users.isFollowing(userID).then(resp => {
      dispatch({
        type: types.USER_FOLLOWING_CHECK,
        userID,
        progress: false
      });
      return dispatch({
        type: types.USER_IS_FOLLOWING,
        userID,
        following: resp
      });
    });
  };
}

function follow(userID) {
  return function (dispatch) {
    _apiSdk.legacyAPI.Following.follow(userID).then(() => {
      return dispatch({
        type: types.USER_FOLLOW,
        userID,
        following: true
      });
    });
  };
}

function unfollow(userID) {
  return function (dispatch) {
    _apiSdk.legacyAPI.Following.unfollow(userID).then(() => {
      return dispatch({
        type: types.USER_UNFOLLOW,
        userID,
        following: false
      });
    });
  };
}

function getFollowers(userId) {
  return function (dispatch) {
    _apiSdk.legacyAPI.Users.getFollowing(userId).then(resp => {
      var data = {};
      resp.forEach(user => {
        data[String(user.ID)] = true;
      });
      dispatch({
        type: types.USER_FOLLOWING_SET,
        data
      });
    });
  };
}