"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.update = exports.remove = exports.add = exports.countByOwner = exports.countByFollowedId = exports.fetchByOwner = exports.fetchByfollowedId = exports.fetchMineByfollowedId = exports.fetchMine = exports.getList = void 0;

var _merge2 = _interopRequireDefault(require("lodash/merge"));

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//get
var getList = function () {
  var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return dispatch => {
    return _apiSdk.v2API.Follows.getFollowsByFilter(filter).then(result => dispatch({
      type: T.FOLLOWS_FTC_OK,
      result,
      filter
    }));
  };
};

exports.getList = getList;

var fetchMine = function () {
  var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (dispatch, getState) => {
    var {
      userData
    } = getState();
    var userFilter = (0, _merge2.default)({
      where: {
        owner: userData.id
      }
    }, filter);
    return dispatch(getList(userFilter));
  };
};

exports.fetchMine = fetchMine;

var fetchMineByfollowedId = (followedId, followedType) => fetchMine({
  where: {
    followedId,
    followedType
  }
});

exports.fetchMineByfollowedId = fetchMineByfollowedId;

var fetchByfollowedId = (followedId, followedType, limit, skip) => getList({
  skip,
  limit,
  where: {
    and: [{
      followedId: "".concat(followedId)
    }, {
      followedType
    }]
  }
});

exports.fetchByfollowedId = fetchByfollowedId;

var fetchByOwner = (owner, followedType, limit, skip) => getList({
  skip,
  limit,
  where: {
    and: [{
      owner: "".concat(owner)
    }, {
      followedType
    }]
  }
}); //count


exports.fetchByOwner = fetchByOwner;

var countByFollowedId = (followedId, followedType) => dispatch => _apiSdk.v2API.Follows.getFollowsCounterByFollowedId(followedId, followedType).then(result => result.count >= 0 ? dispatch({
  type: T.FOLLOWS_COUNT_FOLLOWED_OK,
  result,
  followedId,
  followedType
}) : Promise.reject("Not Found"));

exports.countByFollowedId = countByFollowedId;

var countByOwner = (owner, followedType) => dispatch => _apiSdk.v2API.Follows.getFollowsCounterByOwner(owner, followedType).then(result => result.count >= 0 ? dispatch({
  type: T.FOLLOWS_COUNT_OWNER_OK,
  result,
  owner,
  followedType
}) : Promise.reject("Not Found")); //add


exports.countByOwner = countByOwner;

var add = (followedId, followedType) => dispatch => _apiSdk.v2API.Follows.addFollow(followedType, followedId).then(result => dispatch({
  type: T.FOLLOWS_CREATE_OK,
  result
})); // remove


exports.add = add;

var remove = followId => dispatch => _apiSdk.v2API.Follows.dropFollow(followId).then(result => result.count > 0 ? dispatch({
  type: T.FOLLOWS_DELETE_OK,
  followId
}) : Promise.reject("Not Found")); //update


exports.remove = remove;

var update = (followId, fields) => dispatch => _apiSdk.v2API.Follows.updateFollowRecord(followId, fields).then(result => dispatch({
  type: T.FOLLOWS_UPDATE_OK,
  result
}));

exports.update = update;