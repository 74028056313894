"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.upsertOne = upsertOne;
exports.upsertMany = upsertMany;
exports.fetchByIds = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _thunk = require("../thunk");

var _apiSdk = require("@voxmarkets/api-sdk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/**
 * Upserts snapshot of one listing
 * @param {string} listingId - listing id
 * @param {Object} snapshot - listing id
 * @return
 */
function upsertOne(listingId, snapshot) {
  return {
    type: T.UPSERT_PRICE,
    snapshot: snapshot,
    listingId: listingId
  };
}
/**
 * Upserts an entire dictionary of snapshots
 * @param {Object} prices - dictionary keyed by listing id
 * @return
 */


function upsertMany(prices) {
  return {
    type: T.UPSERT_PRICES,
    prices: prices
  };
}

var fetchByIds = function (listingIds) {
  var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return (dispatch, getState) => {
    if (!force) {
      var currentPrices = getState().prices;
      listingIds = listingIds.filter(listingId => !(currentPrices[listingId] && currentPrices[listingId].pending));
    }

    return dispatch((0, _thunk.thunkCreator)({
      types: [T.PRICES.FTC.MANY.RQ, T.PRICES.FTC.MANY.OK, T.PRICES.FTC.MANY.FAI],
      promise: _apiSdk.v2API.Prices.getForListings(listingIds),
      listingIds
    }));
  };
};

exports.fetchByIds = fetchByIds;