"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = rnsReducer;
exports.indexOptions = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _Utils = _interopRequireDefault(require("../Utils"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var indexOptions = [{
  key: null,
  label: "All"
}, {
  key: "watched",
  label: "Watched"
}, {
  key: "5a10cc85ac5de600127fe8bc",
  label: "AIM"
}];
exports.indexOptions = indexOptions;
var initialState = {
  loadingOlder: true,
  loadingNewer: false,
  byStoryId: {},
  byCompanyId: {},
  typeFilterId: 0,
  indexFilterId: 0,
  timeBoundsPerFilter: {},
  filtersWithNoMoreData: [],
  indexOptions
};

function rnsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case T.RNS.FTC.CONTENT_BY.STORY_IDS.OK:
      return insertContents(state, action);

    case T.RNS.SET_FILTERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        typeFilterId: action.typeFilterId,
        indexFilterId: action.indexFilterId
      });

    case T.RNS.FTC.META.OK:
      return setTimeBounds(state, action);

    default:
      return state;
  }
}

function insertContents(state, action) {
  var newState = _objectSpread({}, state);

  for (var storyId in action.result) {
    newState.byStoryId[storyId] = _objectSpread(_objectSpread({}, newState.byStoryId[storyId]), {}, {
      content: action.result[storyId][0].modified.content
    });
  }

  return newState;
}

function setTimeBounds(state, action) {
  //create array of ascending timestamps
  var timestamps = Object.keys(action.result).map(storyId => action.result[storyId].timestampSeconds).sort();

  if (timestamps.length) {
    var currentTimeBounds = state.timeBoundsPerFilter[action.currentFilter] || {
      min: Number.MAX_SAFE_INTEGER,
      max: Number.MIN_SAFE_INTEGER
    };
    return _objectSpread(_objectSpread({}, state), {}, {
      timeBoundsPerFilter: {
        [action.currentFilter]: {
          min: Math.min(currentTimeBounds.min, timestamps[0]),
          max: Math.max(currentTimeBounds.max, timestamps[timestamps.length - 1])
        }
      }
    });
  } else if (action.direction == -1) {
    return _objectSpread(_objectSpread({}, state), {}, {
      filtersWithNoMoreData: [...new Set([...state.filtersWithNoMoreData, action.currentFilter])]
    });
  }

  return state;
}