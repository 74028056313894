"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeItem = exports.addItem = exports.deleteWatchlist = exports.createWatchlist = exports.updateWatchlist = exports.fetchWatchlists = void 0;

var types = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../thunk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fetchWatchlists = () => (0, _thunk.thunkCreatorWithoutConcurrency)({
  types: [types.WLS2_FTC_RQ, types.WLS2_FTC_OK, types.WLS2_FTC_FAI],
  promise: () => _apiSdk.v2API.Watchlists2.getWatchlists()
});

exports.fetchWatchlists = fetchWatchlists;

var updateWatchlist = function (watchlistId) {
  var update = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (0, _thunk.thunkCreatorWithoutConcurrency)({
    watchlistId,
    update,
    types: [types.WL2_UPDATE_RQ, types.WL2_UPDATE_OK, types.WL2_UPDATE_FAI],
    promise: () => _apiSdk.v2API.Watchlists2.updateWatchlistMetadata(watchlistId, update)
  });
};

exports.updateWatchlist = updateWatchlist;

var createWatchlist = watchlistTitle => dispatch => _apiSdk.v2API.Watchlists2.createWatchlist(watchlistTitle).then(result => dispatch({
  type: types.WL2_CREATE_OK,
  result,
  watchlistTitle,
  meta: {
    analytics: {
      eventType: "track",
      eventPayload: {
        event: "create-watchlist",
        properties: {
          watchlistTitle
        }
      }
    }
  }
})).catch(console.log);

exports.createWatchlist = createWatchlist;

var deleteWatchlist = watchlistId => (0, _thunk.thunkCreatorWithoutConcurrency)({
  watchlistId,
  types: [types.WL2_DELETE_RQ, types.WL2_DELETE_OK, types.WL2_DELETE_FAI],
  promise: () => _apiSdk.v2API.Watchlists2.deleteWatchlist(watchlistId)
}); //----------------------


exports.deleteWatchlist = deleteWatchlist;

var addItem = (watchlistId, listingId) => dispatch => _apiSdk.v2API.Watchlists2.createWatchlistsItem(watchlistId, listingId).then(result => dispatch({
  type: types.WL2_ITEM_CREATE_OK,
  result,
  watchlistId,
  listingId,
  meta: {
    analytics: {
      eventType: "track",
      eventPayload: {
        event: "follow-listing",
        properties: {
          listingId
        }
      }
    }
  }
})).catch(console.log);

exports.addItem = addItem;

var removeItem = (itemId, listingId) => dispatch => _apiSdk.v2API.Watchlists2.deleteWatchlistsItem(itemId).then(result => dispatch({
  type: types.WL2_ITEM_DELETE_OK,
  result,
  itemId,
  meta: {
    analytics: {
      eventType: "track",
      eventPayload: {
        event: "unfollow-listing",
        properties: {
          listingId
        }
      }
    }
  }
})).catch(console.log);

exports.removeItem = removeItem;