"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitContent = exports.fetchEntityTimeline = exports.fetchUserTimeline = exports.fetchCompanyTimeline = exports.fetchMyTimeline = exports.dispatchIfNotPending = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../thunk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var dispatchIfNotPending = action => {
  return (dispatch, getState) => {
    var {
      loading,
      refreshing
    } = getState().timeline;

    if (!loading && !refreshing) {
      return dispatch(action);
    }

    return () => {};
  };
};

exports.dispatchIfNotPending = dispatchIfNotPending;

var fetchMyTimeline = function () {
  var timestamp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 20;
  return (0, _thunk.thunkCreator)({
    types: [T.FTC_MY_TLN_RQ, T.FTC_MY_TLN_OK, T.FTC_MY_TLN_FAI],
    promise: _apiSdk.v2API.Timeline.getMyTimeline({
      timestamp,
      limit
    })
  });
};

exports.fetchMyTimeline = fetchMyTimeline;

var fetchCompanyTimeline = function (companyId) {
  var timestamp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;
  return (0, _thunk.thunkCreator)({
    entityId: companyId,
    types: [T.FTC_CPNY_TLN_RQ, T.FTC_CPNY_TLN_OK, T.FTC_CPNY_TLN_FAI],
    promise: _apiSdk.v2API.Timeline.getCompanyTimeline(companyId, {
      timestamp,
      limit
    })
  });
};

exports.fetchCompanyTimeline = fetchCompanyTimeline;

var fetchUserTimeline = function (userId) {
  var timestamp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;
  return (0, _thunk.thunkCreator)({
    entityId: userId,
    types: [T.FTC_USER_TLN_RQ, T.FTC_USER_TLN_OK, T.FTC_USER_TLN_FAI],
    promise: _apiSdk.v2API.Timeline.getUserTimeline(userId, {
      timestamp,
      limit
    })
  });
};

exports.fetchUserTimeline = fetchUserTimeline;

var fetchEntityTimeline = function (entityId) {
  var timestamp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;
  return (0, _thunk.thunkCreator)({
    entityId,
    types: [T.FTC_ENTITY_TLN_RQ, T.FTC_ENTITY_TLN_OK, T.FTC_ENTITY_TLN_FAI],
    promise: _apiSdk.v2API.Timeline.getEntityTimeline(entityId, {
      timestamp,
      limit
    })
  });
};

exports.fetchEntityTimeline = fetchEntityTimeline;

var submitContent = function (content) {
  var targets = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return (0, _thunk.thunkCreatorWithoutConcurrency)({
    types: [T.POST_TO_TLN_RQ, T.POST_TO_TLN_OK, T.POST_TO_TLN_FAI],
    promise: () => _apiSdk.v2API.Timeline.createEntry(content, targets, options)
  });
};

exports.submitContent = submitContent;