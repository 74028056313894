"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = newsMetadataReducer;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _Utils = _interopRequireDefault(require("../Utils"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  loadingOlder: false,
  loadingNewer: false,
  byStoryId: {},
  mentions: {}
};

function newsMetadataReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case T.RNS.FTC.META.REFRESH:
      return setLoadingNewer(state);

    case T.RNS.FTC.META.RQ:
      return setLoadingOlder(state);

    case T.RNS.FTC.META.OK:
      return insertStories(state, action);

    case T.RNS_FTC_META_OK:
      var byStoryId = action.result.reduce((acc, asset) => {
        asset.date = asset.timestamp;
        acc[asset.storyId] = asset;
        return acc;
      }, _objectSpread({}, state.byStoryId));
      return _objectSpread(_objectSpread({}, state), {}, {
        byStoryId
      });

    default:
      return state;
  }
}

function setLoadingOlder(state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loadingOlder: true
  });
}

function setLoadingNewer(state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loadingNewer: true
  });
}

function insertStories(state, action) {
  var newMentions = {};
  Object.keys(action.result).forEach(storyId => {
    var {
      mentions
    } = action.result[storyId];

    if (Array.isArray(mentions)) {
      mentions.forEach(mention => {
        if (mention && mention.id) {
          if (newMentions[mention.id]) {
            newMentions[mention.id].add(storyId);
          } else {
            newMentions[mention.id] = new Set([storyId]);
          }
        }
      });
    }
  });
  Object.keys(newMentions).forEach(listingId => {
    if (state.mentions[listingId]) {
      var combinedSet = new Set(state.mentions[listingId]);
      newMentions[listingId].forEach(storyId => combinedSet.add(storyId));
      newMentions[listingId] = Array.from(combinedSet);
    } else {
      newMentions[listingId] = Array.from(newMentions[listingId]);
    }
  });
  return _objectSpread(_objectSpread({}, state), {}, {
    loadingOlder: false,
    loadingNewer: false,
    byStoryId: _Utils.default.upsertMany(state.byStoryId, action.result),
    mentions: _objectSpread(_objectSpread({}, state.mentions), newMentions)
  });
}