"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchByTags = exports.fetchByFilter = exports.fetchByKeyword = exports.fetchForWatchedCompanies = exports.fetchForMarket = exports.fetchCompanyRelevantNews = exports.fetchForStories = exports.fetchWithinRange = exports.getMeta = exports.fetchAfterTimestamp = exports.fetchBeforeTimestamp = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../thunk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var injectFilterProp = action => (dispatch, getState) => {
  var {
    rns
  } = getState();
  return dispatch((0, _thunk.thunkCreator)(_objectSpread(_objectSpread({}, action), {}, {
    currentFilter: "".concat(rns.typeFilterId, "#").concat(rns.indexFilterId)
  })));
};

var fetchBeforeTimestamp = (timestamp, rnsCats, skip) => getMeta(timestamp, rnsCats, -1, skip);

exports.fetchBeforeTimestamp = fetchBeforeTimestamp;

var fetchAfterTimestamp = (timestamp, rnsCats, skip) => getMeta(timestamp, rnsCats, 1, skip);

exports.fetchAfterTimestamp = fetchAfterTimestamp;

var getMeta = (timestamp, rnsCats, direction, skip) => {
  var include = rnsCats.filter(c => c.charAt(0) !== "!");
  var exclude = rnsCats.filter(c => c.charAt(0) === "!").map(c => c.substr(1));
  return injectFilterProp({
    direction,
    types: [direction == 1 ? T.RNS.FTC.META.REFRESH : T.RNS.FTC.META.RQ, T.RNS.FTC.META.OK, T.RNS.FTC.META.FAI],
    promise: _apiSdk.v2API.NewsMetadata.getMeta(timestamp, include, exclude, direction, skip)
  });
};
/**
 * @param {String|Number} startingAt - oldest date
 * @param {String|Number} endingAt - newest date
 */


exports.getMeta = getMeta;

var fetchWithinRange = (startingAt, endingAt, rnsCats, excludedCats, limit) => injectFilterProp({
  types: [T.RNS.FTC.META.RQ, T.RNS.FTC.META.OK, T.RNS.FTC.META.FAI],
  promise: _apiSdk.v2API.NewsMetadata.getWithinRange(startingAt, endingAt, rnsCats, excludedCats, limit)
});

exports.fetchWithinRange = fetchWithinRange;

var fetchForStories = storyIds => dispatch => dispatch((0, _thunk.thunkCreator)({
  types: [T.RNS.FTC.META.RQ, T.RNS.FTC.META.OK, T.RNS.FTC.META.FAI],
  promise: _apiSdk.v2API.NewsMetadata.getByStoryIds(storyIds)
}));

exports.fetchForStories = fetchForStories;

var fetchCompanyRelevantNews = (companyProps, before, direction, skip) => {
  return injectFilterProp({
    direction,
    types: [T.RNS.FTC.META.RQ, T.RNS.FTC.META.OK, T.RNS.FTC.META.FAI],
    promise: _apiSdk.v2API.NewsMetadata.getForCompany(companyProps, before, direction, skip)
  });
};

exports.fetchCompanyRelevantNews = fetchCompanyRelevantNews;

var fetchForMarket = (marketIndexId, timestamp, direction, skip) => injectFilterProp({
  direction,
  types: [T.RNS.FTC.META.RQ, T.RNS.FTC.META.OK, T.RNS.FTC.META.FAI],
  promise: _apiSdk.v2API.NewsMetadata.getForMarket(marketIndexId, timestamp, direction, skip)
});

exports.fetchForMarket = fetchForMarket;

var fetchForWatchedCompanies = (timestamp, direction, skip) => (dispatch, getState) => dispatch(injectFilterProp({
  direction,
  types: [T.RNS.FTC.META.RQ, T.RNS.FTC.META.OK, T.RNS.FTC.META.FAI],
  promise: _apiSdk.v2API.NewsMetadata.getForUser(getState().userData.id, timestamp, direction, skip)
}));

exports.fetchForWatchedCompanies = fetchForWatchedCompanies;

var fetchByKeyword = keyword => injectFilterProp({
  types: [T.RNS.FTC.META.RQ, T.RNS.FTC.META.OK, T.RNS.FTC.META.FAI],
  promise: _apiSdk.v2API.NewsMetadata.search(keyword, true)
});

exports.fetchByKeyword = fetchByKeyword;

var fetchByFilter = function () {
  var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return dispatch => _apiSdk.v2API.NewsMetadata.getList(filter).then(result => dispatch({
    type: T.RNS_FTC_META_OK,
    result
  }));
};

exports.fetchByFilter = fetchByFilter;

var fetchByTags = function (tags) {
  var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  var skip = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var fromTimestap = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : Math.floor(Date.now() / 1000);
  return (//now
    fetchByFilter({
      limit,
      skip,
      order: "timestamp DESC",
      where: {
        tags: {
          inq: tags
        },
        timestampSeconds: {
          lte: fromTimestap
        }
      }
    })
  );
};

exports.fetchByTags = fetchByTags;