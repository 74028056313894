"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reducer = _interopRequireDefault(require("./notifications/reducer"));

var _reducer2 = _interopRequireDefault(require("./likes/reducer"));

var _reducer3 = _interopRequireDefault(require("./prices/reducer"));

var _reducers = _interopRequireDefault(require("./markets/reducers"));

var _reducer4 = _interopRequireDefault(require("./indexes/reducer"));

var _reducer5 = _interopRequireDefault(require("./trades/reducer"));

var _reducer6 = _interopRequireDefault(require("./userData/reducer"));

var _reducer7 = _interopRequireDefault(require("./charts/reducer"));

var _reducer8 = _interopRequireDefault(require("./companyTimeline/reducer"));

var _reducer9 = _interopRequireDefault(require("./feedback/reducer"));

var _reducer10 = _interopRequireDefault(require("./userTimeline/reducer"));

var _reducer11 = _interopRequireDefault(require("./newsMetadata/reducer"));

var _reducer12 = _interopRequireDefault(require("./reports/reducer"));

var _reducer13 = _interopRequireDefault(require("./shortPositions/reducer"));

var _reducer14 = _interopRequireDefault(require("./markets/indices/reducer"));

var _reducer15 = _interopRequireDefault(require("./tips/reducer"));

var _reducer16 = _interopRequireDefault(require("./authentication/reducer"));

var _reducer17 = _interopRequireDefault(require("./users/reducer"));

var _reducer18 = _interopRequireDefault(require("./teams/reducer"));

var _reducer19 = _interopRequireDefault(require("./people/reducer"));

var _reducer20 = _interopRequireDefault(require("./markets/movers/reducer"));

var _reducer21 = _interopRequireDefault(require("./staticData/reducer"));

var _reducer22 = _interopRequireDefault(require("./rns/reducer"));

var _reducer23 = _interopRequireDefault(require("./reactions/reducer"));

var _reducer24 = _interopRequireDefault(require("./userReactions/reducer"));

var _reducer25 = _interopRequireDefault(require("./follows/reducer"));

var _reducer26 = _interopRequireDefault(require("./timeline/reducer"));

var _reducer27 = _interopRequireDefault(require("./chronology/reducer"));

var _reducer28 = _interopRequireDefault(require("./previews/reducer"));

var _reducer29 = _interopRequireDefault(require("./priceAlerts/reducer"));

var _reducer30 = _interopRequireDefault(require("./shortPositionChanges/reducer"));

var _reducer31 = _interopRequireDefault(require("./relatedFollows/reducer"));

var _reducer32 = _interopRequireDefault(require("./suggestedFollows/reducer"));

var _reducer33 = _interopRequireDefault(require("./brokerRecommendations/reducer"));

var _reducer34 = _interopRequireDefault(require("./ownership/reducer"));

var _reducer35 = _interopRequireDefault(require("./events/reducer"));

var _reducer36 = _interopRequireDefault(require("./advisors/reducer"));

var _reducer37 = _interopRequireDefault(require("./advCompanies/reducer"));

var _reducer38 = _interopRequireDefault(require("./quotes/reducer"));

var _reducer39 = _interopRequireDefault(require("./watchlists2/reducer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = {
  prices: _reducer3.default,
  notifications: _reducer.default,
  markets: _reducers.default,
  indexes: _reducer4.default,
  trades: _reducer5.default,
  userData: _reducer6.default,
  likes: _reducer2.default,
  charts: _reducer7.default,
  companyTimeline: _reducer8.default,
  feedback: _reducer9.default,
  userTimeline: _reducer10.default,
  newsMetadata: _reducer11.default,
  reports: _reducer12.default,
  shortPositions: _reducer13.default,
  marketIndices: _reducer14.default,
  tips: _reducer15.default,
  authentication: _reducer16.default,
  users: _reducer17.default,
  teams: _reducer18.default,
  people: _reducer19.default,
  marketMovers: _reducer20.default,
  staticData: _reducer21.default,
  rns: _reducer22.default,
  reactions: _reducer23.default,
  userReactions: _reducer24.default,
  follows: _reducer25.default,
  priceAlerts: _reducer29.default,
  timeline: _reducer26.default,
  chronology: _reducer27.default,
  previews: _reducer28.default,
  shortPositionChanges: _reducer30.default,
  relatedFollows: _reducer31.default,
  suggestedFollows: _reducer32.default,
  brokerRecommendations: _reducer33.default,
  ownership: _reducer34.default,
  events: _reducer35.default,
  advisors: _reducer36.default,
  advCompanies: _reducer37.default,
  quotes: _reducer38.default,
  watchlists2: _reducer39.default
};
exports.default = _default;