"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.upsertOne = upsertOne;
exports.upsertMany = upsertMany;
exports.getByIds = exports.getById = exports.getList = exports.fetchCompanyIssues = exports.fetchByIds = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _thunk = require("../thunk");

var _apiSdk = require("@voxmarkets/api-sdk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function upsertOne(issue) {
  return {
    type: T.ISSUE_UPSERT,
    issue: issue
  };
}

function upsertMany(issues) {
  return {
    type: T.ISSUES.FTC.MANY.OK,
    result: issues
  };
}

var fetchByIds = function (issueIds) {
  var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var include = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  return (dispatch, getState) => {
    if (!force) {
      var currentIssues = getState().issues;
      issueIds = issueIds.filter(issueId => !(currentIssues[issueId] && currentIssues[issueId].pending));
    }

    return dispatch((0, _thunk.thunkCreator)({
      types: [T.ISSUES.FTC.MANY.RQ, T.ISSUES.FTC.MANY.OK, T.ISSUES.FTC.MANY.FAI],
      promise: _apiSdk.v2API.Issues.getByIds(issueIds, include).then(data => {
        return data;
      }),
      issueIds
    }));
  };
};

exports.fetchByIds = fetchByIds;

var fetchCompanyIssues = function (companyId) {
  var includeListings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return (0, _thunk.thunkCreator)({
    companyId,
    types: [T.ISSUES.FTC.MANY.RQ, T.ISSUES.FTC.MANY.OK, T.ISSUES.FTC.MANY.FAI],
    promise: _apiSdk.v2API.Issues.getByCompanyId(companyId, includeListings).then(data => {
      return data.reduce((indexed, issue) => {
        indexed[issue.id] = Object.assign(_apiSdk.v2API.Issues.mapLoopbackFields(issue), {
          id: issue.id
        });
        return indexed;
      }, {});
    })
  });
}; //----------------------------------
// Returns DB Type Object nad promises
//------------------------------
// Returns DB Type Object


exports.fetchCompanyIssues = fetchCompanyIssues;

var getList = filter => (0, _thunk.thunkCreatorWithoutConcurrency)({
  filter,
  types: [T.ISSUES_GET_LIST_RQ, T.ISSUES_GET_LIST_OK, T.ISSUES_GET_LIST_FAI],
  promise: () => _apiSdk.v2API.Issues.getList(filter)
});

exports.getList = getList;

var getById = id => {
  return getList({
    where: {
      id
    }
  });
};

exports.getById = getById;

var getByIds = Ids => {
  return getList({
    where: {
      id: {
        inq: Ids
      }
    }
  });
};

exports.getByIds = getByIds;