"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = usersReducer;

var T = _interopRequireWildcard(require("../actionTypes.js"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {};

function usersReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case T.USERS.SET.ONE.AVATAR:
      return setAvatarURI(state, action);

    case T.USERS.SET.ONE.NAME:
      return setName(state, action);

    case T.USERS.SET.ONE.DATA:
      return setUserData(state, action);

    case T.AUTH.WORDPRESS.LOGIN.CREDENTIALS.OK:
      return onWPLogin(state, action);

    case T.FTC_USERS_BY_LOGIN_OK:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.result.ID]: _objectSpread(_objectSpread({}, state[action.result.ID]), {}, {
          id: action.result.ID,
          name: action.result.display_name,
          login: action.result.user_login
        })
      });

    case T.FTC_USERS_DATA_OK:
      var data = action.result[0];
      var follow = action.result[1];
      var posts = action.result[2];
      var basicData = action.result[3];
      var profilePicture = action.result[4];
      if (basicData && basicData.error !== true) return _objectSpread(_objectSpread({}, state), {}, {
        [action.userId]: _objectSpread(_objectSpread({}, state[action.userId]), {}, {
          id: action.userId,
          name: basicData.name,
          firstName: data[1],
          lastName: data[6],
          login: basicData.user_login,
          about: data["51"],
          title: data["7"] && data["7"].length > 0 ? data["7"][0] : null,
          avatarURI: profilePicture ? profilePicture.uri : "",
          followersCounter: follow && follow.followerCount,
          followingCounter: follow && follow.followingCount,
          squawksCounter: posts
        })
      });
      return state;

    default:
      return state;
  }
}

function setAvatarURI(state, action) {
  return Object.assign({}, state, {
    [action.userId]: Object.assign({}, state[action.userId], {
      avatarURI: action.uri
    })
  });
}

function setName(state, action) {
  return Object.assign({}, state, {
    [action.userId]: Object.assign({}, state[action.userId], {
      name: action.name
    })
  });
}

function onWPLogin(state, action) {
  if (action && action.result && action.result.user) {
    var {
      user
    } = action.result;
    return Object.assign({}, state, {
      [user.id]: Object.assign({}, state[user.id], {
        name: user.displayname,
        email: user.email,
        avatarURI: user.avatar
      })
    });
  }

  return state;
}

function setUserData(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    [action.userId]: _objectSpread(_objectSpread({}, state[action.userId]), action.data)
  });
}