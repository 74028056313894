"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = listingsReducer;

var _filter2 = _interopRequireDefault(require("lodash/filter"));

var _map2 = _interopRequireDefault(require("lodash/map"));

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _Utils = _interopRequireDefault(require("../Utils"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  byId: {},
  user: {},
  company: {},
  personal: [],
  loading: false,
  refreshing: false,
  timelines: {},
  reactions: {},
  me: {
    timeline: [],
    reactions: {}
  }
};

function listingsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case T.TLN.FTC.RQ:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });

    case T.TLN.FTC.REFRESH:
      return _objectSpread(_objectSpread({}, state), {}, {
        refreshing: true
      });

    case T.TLN.FTC.OK.PERSONAL:
    case T.TLN.FTC.OK.CPNY:
      return insertData(state, action, "company");

    case T.TLN.FTC.OK.USER:
      return insertData(state, action, "user");

    case T.TLN.POST.RQ.PERSONAL:
      return insertPending(state, action);

    case T.TLN.POST.OK.PERSONAL:
      return confirmPending(state, action);

    case T.TLN.POST.FAI.PERSONAL:
      return markAsFailed(state, action);

    case T.TLN.POST.RETRY.PERSONAL:
      return markAsPending(state, action);

    case T.FTC_MY_TLN_OK:
      // add these to the collection
      return _objectSpread(_objectSpread({}, state), {}, {
        me: _objectSpread(_objectSpread({}, state.me), {}, {
          timeline: mergeTimelines(state.me.timeline, action.result.timeline)
        })
      });

    case T.FTC_CPNY_TLN_OK:
    case T.FTC_USER_TLN_OK:
    case T.FTC_ENTITY_TLN_OK:
      // get the existing collection of timeline items for this company
      var existingTimeline = state.timelines.hasOwnProperty(action.entityId) ? state.timelines[action.entityId] : []; // merge the new items

      return _objectSpread(_objectSpread({}, state), {}, {
        timelines: _objectSpread(_objectSpread({}, state.timelines), {}, {
          [action.entityId]: mergeTimelines(existingTimeline, action.result.timeline)
        })
      });

    default:
      return state;
  }
}

function markAsPending(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    byId: _objectSpread(_objectSpread({}, state.byId), {}, {
      [action.pendingSquawk.id]: _objectSpread(_objectSpread({}, action.pendingSquawk), {}, {
        pending: true,
        failed: false
      })
    })
  });
}

function combineIds(oldIds, newIds) {
  var combined = new Set(oldIds);
  newIds.forEach(squawkId => combined.add(squawkId));
  return Array.from(combined);
}

function insertPending(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    byId: _objectSpread(_objectSpread({}, state.byId), {}, {
      [action.pendingSquawk.id]: action.pendingSquawk
    }),
    personal: combineIds(state.personal, [action.pendingSquawk.id])
  });
}

function confirmPending(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    byId: _objectSpread(_objectSpread({}, state.byId), {}, {
      [action.result.id]: _objectSpread(_objectSpread({}, action.pendingSquawk), {}, {
        id: action.result.id,
        pending: false,
        failed: false
      })
    }),
    personal: combineIds(state.personal.filter(id => id != action.pendingSquawk.id), [action.result.id])
  });
}

function markAsFailed(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    byId: _objectSpread(_objectSpread({}, state.byId), {}, {
      [action.pendingSquawk.id]: _objectSpread(_objectSpread({}, state.byId[action.pendingSquawk.id]), {}, {
        failed: true
      })
    })
  });
}

function insertPersonal(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    refreshing: false,
    byId: _objectSpread(_objectSpread({}, state.byId), action.result.squawks),
    personal: combineIds(state.personal, Object.keys(action.result.reactions))
  });
}

function insertData(state, action, key) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    refreshing: false,
    byId: _objectSpread(_objectSpread({}, state.byId), action.result.squawks),
    [key]: _objectSpread(_objectSpread({}, state[key]), {}, {
      [action.id]: combineIds(state[key][action.id], Object.keys(action.result.reactions))
    })
  });
}

function removeForEach(object, idToOmit) {
  var byId = {};
  Object.keys(object).forEach(key => {
    byId[key] = object[key].filter(id => id != idToOmit);
  });
  return byId;
}

var mergeTimelines = (currentTimeline, inboundTimeline) => {
  // gets the list of postIds for the user's timeline as we have it already
  var postIdList = (0, _map2.default)(currentTimeline, post => post.id); // filter the inbound posts to exclude any that we have already

  var freshPosts = (0, _filter2.default)(inboundTimeline, post => postIdList.indexOf(post.id) === -1);
  return [...currentTimeline, ...freshPosts];
};