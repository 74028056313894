"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var types = _interopRequireWildcard(require("../actionTypes.js"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

// combine reducers rather then one big reducer
// https://redux.js.org/recipes/structuringreducers/usingcombinereducers
//---------------------------
function lists() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.WLS2_FTC_OK:
      return action.result.reduce((state, watchlist) => {
        var {
          items
        } = watchlist,
            rest = _objectWithoutProperties(watchlist, ["items"]); //move items to separate list to keep store flat


        state[watchlist.id] = rest;
        return state;
      }, _objectSpread({}, state));

    case types.WL2_DELETE_OK:
      delete state[action.watchlistId];
      return _objectSpread({}, state);

    case types.WL2_UPDATE_OK:
    case types.WL2_CREATE_OK:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.result.id]: action.result
      });

    default:
      return state;
  }
} //---------------------------


function items() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.WLS2_FTC_OK:
      //flatten items
      var watchlistsItems = action.result.reduce((a, watchlist) => {
        return [...a, ...watchlist.items];
      }, []); //add items to separate list to keep store structure flat
      // https://redux.js.org/recipes/structuringreducers/normalizingstateshape

      return watchlistsItems.reduce((state, watchlistItem) => {
        state[watchlistItem.id] = watchlistItem;
        return state;
      }, _objectSpread({}, state));

    case types.WL2_ITEM_CREATE_OK:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.result.id]: action.result
      });

    case types.WL2_ITEM_DELETE_OK:
      var newState = {};
      Object.keys(state).forEach(itemId => {
        if (itemId != action.itemId) {
          newState[itemId] = state[itemId];
        }
      });
      return newState;

    case types.WL2_DELETE_OK:
      Object.values(state).forEach(item => {
        if (item.watchlistId === action.watchlistId) delete state[item.id];
      });
      return _objectSpread({}, state);

    default:
      return state;
  }
}

var _default = (0, _redux.combineReducers)({
  lists,
  items
});

exports.default = _default;