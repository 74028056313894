"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = likesReducer;

var types = _interopRequireWildcard(require("../actionTypes.js"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var Utils = require("../Utils");

var initialState = {};

function likesReducer() {
  var s = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case types.TOGGLE_POST:
      {
        var id = "".concat(action.postId);
        var old = s[id] ? s[id] : {};
        var current = defaultLikeObj(old); //if same action again - reduce count;

        if (action.toggleType == current.status) {
          current.count[action.toggleType]--;
          current.status = "none";
        } else {
          //reduce count of previous status if not none.
          if (current.status != "none") {
            current.count[current.status]--;
          } //increase count of current status


          current.count[action.toggleType]++; //set current status

          current.status = action.toggleType;
        }

        return Object.assign({}, s, composeObject(id, current));
      }
      break;

    case types.ADD_POSTS_INFO:
      return Object.assign({}, s, action.likesInfo);
      break;

    case types.POST_INFO:
      {
        var _id = "".concat(action.postId);

        return Object.assign({}, s, composeObject(_id, action.likeInfo));
      }
      break;

    default:
      return s;
  }
}

function composeObject(id, state) {
  var obj = {};
  obj[id] = state;
  return obj;
}

function defaultLikeObj(state) {
  return Object.assign({
    status: "none",
    count: {
      like: 0,
      dislike: 0
    }
  }, state);
}