"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.thunkCreatorWithoutConcurrency = exports.thunkCreator = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var thunkCreator = action => {
  var {
    types,
    promise
  } = action,
      rest = _objectWithoutProperties(action, ["types", "promise"]);

  var [RQED, RESOLVED, REJECTED] = types;
  return dispatch => {
    dispatch(_objectSpread(_objectSpread({}, rest), {}, {
      type: RQED
    }));
    return promise.then(result => {
      if (result && result.error) {
        throw result.error;
      }

      dispatch(_objectSpread(_objectSpread({}, rest), {}, {
        type: RESOLVED,
        result
      }));
      return result;
    }).catch(error => {
      dispatch(_objectSpread(_objectSpread({}, rest), {}, {
        type: REJECTED,
        error
      }));
      throw error;
    });
  };
};

exports.thunkCreator = thunkCreator;
var pendingRequests = {};

var thunkCreatorWithoutConcurrency = action => {
  var {
    types,
    promise
  } = action,
      rest = _objectWithoutProperties(action, ["types", "promise"]);

  var [RQED, RESOLVED, REJECTED] = types;
  var thunkId = "".concat(RQED, "-").concat(JSON.stringify(rest));
  return dispatch => {
    dispatch(_objectSpread(_objectSpread({}, rest), {}, {
      type: RQED
    }));

    if (!pendingRequests.hasOwnProperty(thunkId)) {
      pendingRequests[thunkId] = promise();
    }

    return pendingRequests[thunkId].then(result => {
      delete pendingRequests[thunkId];

      if (result && result.error) {
        throw result.error;
      }

      return dispatch(_objectSpread(_objectSpread({}, rest), {}, {
        type: RESOLVED,
        result
      }));
    }).catch(error => {
      delete pendingRequests[thunkId];
      return dispatch(_objectSpread(_objectSpread({}, rest), {}, {
        type: REJECTED,
        error
      }));
    });
  };
};

exports.thunkCreatorWithoutConcurrency = thunkCreatorWithoutConcurrency;